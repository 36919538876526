import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import RightDrawer from '../../Global/RightDrawer'
import { connect } from 'react-redux'
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import '../../../scss/_dashboardRightDrawer.scss'
import { exportAllDocs } from '../../../store/actions/exports';
import NotifierSnackbar, { openSnackbar } from '../../Global/NotifierSnackbar'
import ReactToPrint from 'react-to-print';
import moment from 'moment'
import MapContainer from '../../common/map';

const DashboardRightDrawer = props => {
  const [mapVisible, setMapVisible] = useState(false)
  const dataFormat = value => {
    return moment(value).format('DD-MM-YYYY')
  }


  const componentRef = useRef();
  const renderForm = (formData, structures) => {
    const renderObj = (obj, field = "") => {
      if (obj) {
        if (typeof (obj) == "string") {
          if (field && field.field_type === "image") {
            return (<img src={obj} width="100" />)
          }
          if (field && field.field_type === "date") {
            return (<div>{dataFormat(obj)}</div>)
          } else {
            return obj
          }

        }
        if (typeof (obj) == "object") {
          if (field && field.field_type === "geo-location") {
            return (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ marginRight: '20px' }}>{obj.latitude},{obj.longitude}</div>
              <div><div onClick={() => setMapVisible(!mapVisible)} style={{ cursor: 'pointer' }}>{mapVisible ? 'Hide Map' : 'View on map'}</div>
                {
                  field.field_type == 'geo-location' && mapVisible ?
                    <MapContainer style={{ position: 'inherit' }} data={obj} />
                    : null
                }
              </div>
            </div>)
          }
          if (field && field.field_type === "beneficiaries") {
            return (<div>{obj.beneficiary_name}</div>)
          }
          if (field && field.field_type === "dropdown") {
            return (<div>{obj.length > 0 ? obj[0] : obj}</div>)
          }
          if (field && field.field_type === "signature") {
            return (<img src={obj.value} width="100" />)
          }
          if (field && field.field_type === "date") {
            return (<div>{dataFormat(obj)}</div>)
          }
          else
            return JSON.stringify(obj)

        }
        return JSON.stringify(obj)
      }
    }
    if (structures)
      return structures.map((field, i) => {
        if (Array.isArray(field.extra_details) && field.extra_details.length > 0) {
          if (field.field_type === "field-group") {
            return (
              // <div style={{ width: '100%' }}>
              <TableRow key={i} style={{ marginTop: '20px' }}>
                {/* {field.field_label && field.field_label} */}
                <TableCell style={{ borderRight: '2px solid #eeee', width: '1%' }}>
                  {field.field_label && field.field_label}
                </TableCell>
                <TableCell style={{ width: '100%' }}>
                  <Table style={{ width: '100%' }}>
                    {/* <TableHead>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead> */}
                    <TableBody>
                      {
                        Array.isArray(formData[field.field_name]) && formData[field.field_name].map(groupFormData => (
                          groupFormData.value ? renderForm(groupFormData.value, field.extra_details) : null
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
              // </div>
            )
          } else {
            if (formData[field.field_name]) {

              return (
                <TableRow key={i}>
                  <TableCell align="left">
                    {field.field_label && field.field_label}
                  </TableCell>
                  <TableCell align="right">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="right">Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          (renderForm(formData[field.field_name].value, field.extra_details))
                        }
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              )
            }

          }
        }
        return (
          <TableRow key={i}>
            <TableCell align="left">
              {
                Object.keys(formData).length !== 0 && formData[field.field_name] && formData[field.field_name].label && renderObj(formData[field.field_name].label)
              }
              <div className="tableSublabel">
                {field.field_type}
              </div>
            </TableCell>
            <TableCell >
              {/* {renderObj(formData[field.field_name].value, field)} */}
              {/* {field.field_name} */}
              {
                Object.keys(formData).length !== 0 && formData[field.field_name] && formData[field.field_name].value && renderObj(formData[field.field_name].value, field)
              }

            </TableCell>
          </TableRow>
        )
      })
  }

  const exports = () => {
    let formData = {
      id: props.data._id,
      doc_type: props.data.doc_type
    }
    props.exportAllDocs(formData).then(res => {
      // window.open(`https://api.wildconnect.in/${res.data.path}`)
      // window.open(res.data.path)
      // setDocs([res.data])

      openSnackbar({ message: 'We have received your request, you will receive an email shortly to download the report.', status: 'success' })
    })
  }
  return (
    <React.Fragment>
      <NotifierSnackbar />
      <RightDrawer
        handleDrawer={props.handleDrawer}
        drawerClass="dashboardDrawerMain"
        isDrawerOpen={props.isDrawerOpen}

      >
        <Box className="dashboardRightDrawer" ref={componentRef} >
          <Box className="rightBlock_main">
            <Box className="block">
              <span className="blockName">DOCUMENT ID:</span>
              <span className="blockData">{props.data && props.data.local_id}</span>
            </Box>
            <Box className="block">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="blockName">Document Meta Data</span>
                <div style={{ display: 'flex' }} className="print">
                  <span>

                    <ReactToPrint
                      trigger={() => <span style={{ marginRight: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} className="hideIcon"><PrintIcon fontSize="small" />Print</span>}
                      content={() => componentRef.current}
                    />

                  </span>
                  <span style={{ marginRight: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={exports} className="hideIcon"><GetAppIcon fontSize="small" />Export</span>
                </div>
              </div>
            </Box>

            <Box  >
              <TableRow>
                <TableCell>
                  Created by:
                </TableCell>
                <TableCell style={{ width: '100%' }}>
                  {props.data && props.data.user_object && props.data.user_object.first_name} {props.data && props.data && props.data.user_object && props.data.user_object.last_name}
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell>
                  Creation Time:
                </TableCell>
                <TableCell>
                  {props.data && dataFormat(props.data.createdAt)}
                </TableCell>
              </TableRow>
            </Box>
            <Box className="block">
              <span className="blockName">Document Data</span>
            </Box>
            <Box className="block">
              <span className="blockName">{props.data && props.data && props.data.form_data && Object.keys(props.data.form_data).length !== 0 && props.data.form_fields && props.data.form_fields.structures && renderForm(props.data.form_data, props.data.form_fields.structures)}</span>
            </Box>
          </Box>
        </Box>
      </RightDrawer>
    </React.Fragment>
  )
}

export default connect(null, { exportAllDocs })(DashboardRightDrawer)